import React, { ReactElement, useState } from "react"
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Meta } from '../components/Meta';

interface Props {}

function NotFoundPage(_props: Props): ReactElement {
  return (
    <>
    <Meta title="Page not found" bodyClasses="moon-bg 404-page" />
    <div className="container mx-auto" id="container">
      <Header fixedHeader={true} darkMode={true} />
      <section className="page404-section">
        <div className="min-h-screen z-40">
          <h1 className="mt-20 md:mt-32">Oops!</h1>
          <h4>Page not found</h4>
          <p className="w-full md:w-6/12 px-4 md:px-0 md:mx-auto">The page you are looking for might have been removed, or had its name changed, or is temporarily unavailable.</p>
          <div className="flex justify-center mt-10">
            <a href="/" className="btn btn-primary flex items-center"><b>Go to homepage</b></a>
          </div>
        </div>
        <div className="flex justify-center absolute bottom-0 h-1/2 left-0 right-0">
          <img className="px-4 md:px-0 w-full md:w-8/12 object-contain object-bottom" src="https://cdn-static.ymeet.me/flima/astronault.png" />
        </div>
      </section>
    </div>
    </>
  )
}

export default NotFoundPage
